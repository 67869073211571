import React from "react"
import { Link } from "gatsby";
import styled from "styled-components"
import { theme } from '../../config/website'
import SEO from "../components/SEO/SEO"

const Container = styled.div``

const Title = styled.h1`
    margin-bottom: 16px;
    font-weight: normal;
`

const Content = styled.p``

const Action = styled(Link)`
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Bariol-Bold';
    color: ${theme.primaryColor};

    :hover, :active {
        color: inherit;
    }
`

const NotFound = () => {
  return (
    <Container>
      <SEO />
      <Title>Sorry!</Title>
      <Content>That page or resource could not be found.</Content>
      <Action to="/">Return home</Action>
    </Container>
  )
}

export default NotFound